import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Mehran Farjai</h2>
        <p className="email">
          <a href="mailto:mehranfaraji377@gmail.com">mehranfaraji377 AT gmail DOT com</a>
        </p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>
        Hi, I&apos;m Mehran. I&apos;m a MSc Data Science student at the {' '}
        <a href="https://www.unipd.it/en/">University of Padova</a>
        , Italy. My background is in Industrial Engineering, which I
        studied for my Bachelor&apos;s degree at {' '}
        <a href="https://www.iust.ac.ir/en">Iran University of Science and Technology</a>.
        Recently, I did my internship at {' '}
        <a href="https://signet.dei.unipd.it/">SIGNET LAB</a> under supervision of
        {' '} <a href="https://www.dei.unipd.it/~rossi/">Michele Rossi</a> working
        on Spiking Neural Networks.
        Now, I&apos;m delving into a broad
        range of topics, including Data Analysis, Machine Learning, Deep Learning,
        and Deploying models.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? (
            <Link to="/resume" className="button">
              Learn More
            </Link>
          ) : (
            <Link to="/about" className="button">
              About Me
            </Link>
          )}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">
        &copy; Mehran Faraji <Link to="/">mehranfaraji.site</Link>.
      </p>
    </section>
  </section>
);

export default SideBar;
